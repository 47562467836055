import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PlusIcon from '../Icons/PlusIcon'
import './styles.css'

export default function TopBanner({
  onCloseClick,
  text,
  type,
  to,
  centerCheck = false,
  mweb
}) {
  const history = useNavigate()

  const handleonClick = () => {
    if (to) {
      onCloseClick()

      if (to.startsWith('http')) {
        window.location.href = to
      } else {
        history(to)
      }
    }
  }

  useEffect(() => {
    const navBar = document.querySelector('.desktop-header')

    if (navBar) navBar.style.position = 'relative'

    return () => {
      if (navBar) navBar.style.position = 'static'
    }
  }, [])

  return (
    <div className="top-banner-container" onClick={handleonClick}>
      <div className="top-banner-wrapper">
        <div className={`top-banner-parent-${type}`}>
          <div
            className={`top-banner-text ${
              centerCheck && 'top-banner-text-center'
            }`}
          >
            {text}
          </div>
          {mweb ? (
            <div
              className="top-banner-close-btn"
              role="button"
              onClick={(e) => {
                e.stopPropagation()
                onCloseClick()
              }}
            >
              <PlusIcon />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
